import React, {useState, useEffect} from "react";
import { Button, Input } from "antd";

export default function UserFormData({user, onChangeUserData}) {
    
    const [changePassword, setChangePassword] = useState(false);

    useEffect(() => {
        if (user.uuid) {
            setChangePassword(false);
        } else {
            setChangePassword(true);
        }

    }, [user.uuid])

    const changeUserData = (data) => {
        if (onChangeUserData) {
            onChangeUserData(data);
        }
    }

    return (
        <div className="user-data-form">
            <div className="user-data-input">
                <div className="label">
                    Nom
                </div>
                <div className="input">
                    <Input
                        value={user.username}
                        onChange={event => changeUserData(({username: event.target.value}))}
                        />
                </div>
            </div>
            <div className="user-data-input">
                <div className="label">
                    Email
                </div>
                <div className="input">
                    <Input
                        value={user.email}
                        onChange={event => changeUserData(({email: event.target.value}))}
                        />
                </div>
            </div>
            <div className="user-data-input">
                <div className="label">
                    Mot de passe
                </div>
                <div className="input">
                    {
                        changePassword ? 
                            <Input
                                type="password"
                                value={user.password}
                                onChange={event => changeUserData(({password: event.target.value}))}
                                />
                        :
                        <Button onClick={() => setChangePassword(true)}>
                            Changer le mot de passe
                        </Button>
                    }
                </div>
            </div>
            {
                changePassword ? 
                    <div className="user-data-input">
                        <div className="label">
                            Confirmation
                        </div>
                        <div className="input">
                            <Input
                                type="password"
                                value={user.confirm}
                                onChange={event => changeUserData(({confirm: event.target.value}))}
                                />
                        </div>
                    </div>
                : null
            }
        </div>
    );
}
